@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');

body {
  margin: 0;
  padding: 0;
  background: #000;
  font-family: 'Roboto Condensed', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
  background-color: #000;
  //width: 100%;
  position: relative;

  @media (max-width: 800px) {
    padding-top: 0px;
  }
}

.w-f {
  width: 100%;
}

.h-f {
  min-height: 100vh;
}

.bg-f {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

p {
  font-family: 'Roboto Condensed', serif;
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 1.5;

  .first-letter {
    font-weight: bold;
  }

  @media (max-width: 1280px) {
    font-size: 1.3rem;
  }
}

.mt-l {
  margin-top: 50px !important;
}

.mt-xl {
  margin-top: 80px !important;
}

.mb-l {
  margin-bottom: 50px !important;
}

.pt-l {
  padding-top: 50px !important;
}

.pt-xl {
  padding-top: 100px !important;
}

.pb-l {
  padding-bottom: 50px !important;
}

.pb-xl {
  padding-bottom: 100px !important;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.section-bg {
  background: #171717;
  color: #ddd;
  padding: 32px 0;

  a {
    color: #A82D2F;
  }

  h1, h2, h3, h4, h5, h6 {
    color: #eee;

    .small {
      color: #aaa;
    }
  }

  h2 {
    .big {
      line-height: 1em;
    }

  }

  p {
    color: #ddd;
  }

  &.light {
    background: #ddd;
    color: #171717;

    a {
      color: #b40404;
    }

    h1, h2, h3, h4, h5, h6 {
      color: #171717;

      .small {
        color: #878787;
      }
    }

    p {
      color: #171717;
    }
  }
}

.footer {
  background-color: #000 !important;

  p {
    font-size: .8rem;
  }
}

.hero {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center;
  min-height: 100vh;
  width: 100%;
  padding: 0 !important;

  .center-container {
    margin: auto;
    height: 100%;
    display: flex;

    .content {
      margin: auto;
      margin-top: 0;

      .buttons {
        margin-top: 32px;

        a {
          @media (max-width: 800px) {
            display: block;
            margin-bottom: 8px;
          }
        }
      }
    }
  }

  @media (min-width: 1920px) {
    background-image: url('./hero-bg-big.jpg');
  }

  @media (max-width: 1919px) {
    background-image: url('./hero-bg-medium.jpg');
  }

  @media (max-width: 800px) {
    background-image: url('./hero-bg-medium.jpg');
  }
}

.second-page {
  background-position-y: top !important;

  .center-container {
    height: 100%;
    @media (max-width: 800px) {
      padding: 0;
      width: initial;
    }
  }

  .content {
    padding: 64px 32px 32px 32px;
    height: 100%;

    p {
      font-weight: 300;
      font-size: 1.4rem;

      @media (max-width: 800px) {
        font-size: 1.3rem;
      }

      @media (max-width: 400px) {
        font-size: 1.1rem;
      }
    }

    @media (max-width: 800px) {
      padding: 16px 24px;
    }
    //background: rgba(168, 45, 47, .3);
  }

  @media (min-width: 1920px) {
    background-image: url('./page-2-bg-big.jpg');
  }

  @media (max-width: 1919px) {
    background-image: url('./page-2-bg-medium.jpg');
  }

  @media (max-width: 800px) {
    background-image: url('./page-2-bg-medium.jpg');
  }
}

.header-nav {
  margin-left: 16px;
  text-align: center;

  @media (max-width: 800px) {
    display: none;
  }

  a {
    font-size: 1rem;
    text-decoration: none;
    color: #aaa;
    vertical-align: middle;
    margin-left: 12px;

    &:hover {
      color: #fff;
      text-decoration: none;
    }

    &:focus {
      color: #fff;
      text-decoration: none;
    }
  }
}

.overlay {
  position: absolute;
  z-index: 1;
  text-align: center;
  margin-top: -80px !important;
  width: 100% !important;
  max-width: none !important;
  padding: 0 !important;

  &.grunge-border {
    background-image: url('./grunge-overlay-border.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    height: 200px;
    margin-top: -100px !important;
  }
}

.grunge-h {
  background-image: url('./grunge-overlay-border.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: #fff !important;
  padding: 48px 0;
  font-weight: normal;
}

.after-overlay {
  padding-top: 180px !important;
}

a.button {
  background: rgba(0, 0, 0, .8);
  padding: 12px 16px;
  text-align: center;
  transition: 0.3s;
  border-radius: 8px;
  color: #eee;
  text-decoration: none;
  margin: 0 4px;

  @media (max-width: 1280px) {
    font-size: 1rem;
  }

  @media (max-width: 800px) {
    font-size: .8rem;
  }

  @media (max-width: 299px) {
    font-size: .8rem;
  }

  .fa {
    margin-right: 6px;
  }

  &:hover {
    background: rgba(10, 10, 10, 1);
    transition: 0.3s;
    color: #fff;
  }
}

.center-container {
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 16px;

  &.no-max-width {
    max-width: none;
  }
}

h1, h2 {
  color: #fff;
  font-weight: bold;
}

h1, h2, h3, h4, h5, h6 {
  .dark-bg {
    background: rgba(0, 0, 0, 0.8);
    color: #c90000;
    padding: 8px 32px;
  }
}

h1 {
  font-size: 6rem;
  margin-top: 0 !important;

  .small {
    font-size: .65em;
    font-weight: 300;
    margin-bottom: 0 !important;
  }

  .big {
    display: block;
    margin-top: 0 !important;
    margin-bottom: 16px !important;
    line-height: .5em;
  }

  @media (max-width: 1280px) {
    font-size: 4rem;
  }

  @media (max-width: 800px) {
    font-size: 3rem;
  }

  @media (max-width: 310px) {
    font-size: 2rem;
  }
}

h2 {
  font-size: 3rem;

  .big {
    display: block;
    line-height: .65em;
  }

  .small {
    display: block;
    font-size: .5em;
    font-weight: 300;
    margin-bottom: 4px !important;
  }

  .med {
    font-size: .5em;
    font-weight: normal;
    vertical-align: top;
  }

  @media (max-width: 1280px) {
    font-size: 2rem;
  }
}

.mb-none {
  margin-bottom: 0;
}

.mt-none {
  margin-top: 0;
}

.video-container {
  margin: 16px auto;
  max-width: 50%;

  @media (max-width: 1280px) {
    max-width: 100%;
  }
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe, .embed-container object, .embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.steam-cta {
  width: 600px;
  max-width: 100%;
  height: 200px;
  margin: 0 auto;
  display: block;
  padding: 0;

  @media (max-width: 1280px) {
    width: 100%;
  }
}

.header {
  width: 100%;
  top: 0;
  position: absolute;
  text-align: center;
  padding: 32px 0;

  .center-container {
    padding: 0;
  }
}

.header-logo-link {
  vertical-align: middle;
  display: inline-block;
  text-align: center;

  .header-logo {
    max-width: 400px;
    width: 80%;
    vertical-align: middle;
  }
}

.np-logo {
  max-width: 320px;
  width: 80%;
  margin: 0 auto;
  display: block;
}

.social-banner-link {
  width: 50%;
  max-width: 616px;
  margin: 8px auto;
  display: block;
  position: relative;

  img {
    width: 100%;
  }

  @media (max-width: 1280px) {
    width: 100%;
  }
}

.faq {
  font-size: 1.4rem;

  @media (max-width: 1280px) {
    font-size: 1.2rem;
    padding-top: 16px;
  }

  h2 {
    font-size: 3rem;
    margin-top: 0;

    @media (max-width: 1280px) {
      font-size: 2rem;
    }
  }

  .faq-entry {
    h3 {
      font-size: 2rem;

      @media (max-width: 1280px) {
        font-size: 1.5rem;
      }
    }

    ul {
      list-style-type: disc;
    }
  }
}

.social-icons-container {
  margin: 4px 0;

  &.small {
    margin: 0;

    .social-icon {
      margin: 0 2px;
      width: 32px !important;
      height: 32px !important;
    }
  }

  .social-icon {
    margin: 0 4px;
  }
}

.inline-block {
  display: inline-block;
}

.float-right {
  float: right;
}

.mad-raven-games-logo {
  width: 200px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn .1s ease-in;
}

@keyframes blinkIn {
  0% {
    opacity: 0;
  }
  1% {
    opacity: .5;
  }
  2% {
    opacity: .1;
  }
  3% {
    opacity: .6;
  }
  4% {
    opacity: 0;
  }
  5% {
    opacity: .4;
  }
  6% {
    opacity: .05;
  }
  7% {
    opacity: .5;
  }
  8% {
    opacity: 0;
  }
  9% {
    opacity: .5;
  }
  10% {
    opacity: .1;
  }
  11% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.blink-in {
  animation: blinkIn .5s linear;
}

@keyframes stamp {
  0% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.stamp {
  animation: stamp .5s ease-in;
}

@keyframes bounce {
  0% {
    transform: translateY(0) scale(1);
    opacity: .8;
  }
  50% {
    transform: translateY(-4px) scale(1.05);
    opacity: 1;
  }
  100% {
    transform: translateY(0) scale(1);
    opacity: .8;
  }
}

.bouncing {
  animation: bounce 3s ease-in-out infinite;
}

.ad-fast {
  animation-delay: .1s;
}

.ad-med {
  animation-delay: .2s;
}

.ad-slow {
  animation-delay: .5s;
}

.o-0 {
  opacity: 0;
}

.w-100 {
  width: 100%;
}